import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { FormattedDate } from '@plone/volto/components';

const messages = defineMessages({
  tags: {
    id: 'tags',
    defaultMessage: 'Tags',
  },
  when: {
    id: 'event_when',
    defaultMessage: 'When',
  },
  date: {
    id: 'event_date',
    defaultMessage: 'Date',
  },
  allDates: {
    id: 'event_alldates',
    defaultMessage: 'All dates',
  },
  downloadEvent: {
    id: 'Download Event',
    defaultMessage: 'Download Event',
  },
  where: {
    id: 'event_where',
    defaultMessage: 'Where',
  },
  contactName: {
    id: 'event_contactname',
    defaultMessage: 'Contact Name',
  },
  contactPhone: {
    id: 'event_contactphone',
    defaultMessage: 'Contact Phone',
  },
  attendees: {
    id: 'event_attendees',
    defaultMessage: 'Attendees',
  },
  website: {
    id: 'event_website',
    defaultMessage: 'Website',
  },
  visitWebsite: {
    id: 'visit_external_website',
    defaultMessage: 'Visit external website',
  },
  Director: {
    id: 'Director',
    defaultMessage: 'Director',
  },
  RunningTime: {
    id: 'RunningTime',
    defaultMessage: 'Running Time',
  },
  Country: {
    id: 'Country',
    defaultMessage: 'Country',
  },
  Year: {
    id: 'Year',
    defaultMessage: 'Year',
  },
  Language: {
    id: 'Language',
    defaultMessage: 'Language',
  },
  Subtitles: {
    id: 'Subtitles',
    defaultMessage: 'Subtitles',
  },
  Tickets: {
    id: 'Tickets',
    defaultMessage: 'Buy Ticket',
  },
  Type: {
    id: 'Type',
    defaultMessage: 'Type',
  },
});

const ScreeningDetails = ({ content, display_as = 'aside' }) => {
  const intl = useIntl();

  return (
    <>
      <div className="screening details">
        <div className="row">
          <div className="detail">
            <p className="title">{intl.formatMessage(messages.date)}: </p>
            <FormattedDate
              className="date"
              locale={'nl'}
              date={content.start}
              includeTime
            />
          </div>
          <div className="detail">
            <p className="title">{intl.formatMessage(messages.Type)}: </p>
            <p>{content.screening_type.title}</p>
          </div>
          {content.location && (
            <div className="detail">
              <p className="title"> {intl.formatMessage(messages.where)}: </p>
              <p>{content.location}</p>
            </div>
          )}
          {content.ticket_link && (
            <div className="detail">
              <p className="title"> {intl.formatMessage(messages.Tickets)}: </p>
              <p>
                <a
                  href={content.ticket_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage(messages.Tickets)}
                </a>
              </p>
            </div>
          )}
        </div>
        <div className="row">
          {content.director && (
            <div className="detail">
              <p className="title">{intl.formatMessage(messages.Director)}: </p>
              <p>{content.director}</p>
            </div>
          )}
          {content.running_time && (
            <div className="detail">
              <p className="title">
                {intl.formatMessage(messages.RunningTime)}:{' '}
              </p>
              <p>{content.running_time}</p>
            </div>
          )}
          {content.country && (
            <div className="detail">
              <p className="title">{intl.formatMessage(messages.Country)}: </p>
              <p>{content.country}</p>
            </div>
          )}
          {content.year && (
            <div className="detail">
              <p className="title">{intl.formatMessage(messages.Year)}: </p>
              <p>{content.year}</p>
            </div>
          )}
        </div>
        <div className="row">
          {content.film_language && (
            <div className="detail">
              <p className="title">
                {' '}
                {intl.formatMessage(messages.Language)}:{' '}
              </p>
              <p>{content.film_language}</p>
            </div>
          )}
          {content.subtitles && (
            <div className="detail">
              <p className="title">
                {' '}
                {intl.formatMessage(messages.Subtitles)}:{' '}
              </p>
              <p>{content.subtitles}</p>
            </div>
          )}
        </div>
        {content.subjects?.length > 0 && (
          <div className="row tags">
            <div className="detail">
              <p className="title"> {intl.formatMessage(messages.tags)}: </p>
              {content.subjects.map((subject, index) => {
                return (
                  <p key={index.toString()} className="subject">
                    {subject}
                  </p>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ScreeningDetails;
