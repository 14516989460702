import React from 'react';
import { Grid, Container } from 'semantic-ui-react';
import { Image } from 'semantic-ui-react';

import AFK from '@package/constants/Icons/AFK.png';
import FONDS21 from '@package/constants/Icons/FONDS21.jpeg';
import Hivos from '@package/constants/Icons/Hivos.png';
import NLFF from '@package/constants/Icons/NLFF.png';

const PreFooter = ({ content }) => {
  return (
    <>
      <div className="pre-footer">
        <Container>
          <Grid stackable columns={4}>
            <Grid.Column className="left">
              <div className={`icon-wrapper logo`}>
                <a href="https://hivos.nl/free-to-be-me/?utm_source=RFD&utm_medium=website&utm_campaign=RFD">
                  <Image
                    className="document-image"
                    src={Hivos}
                    alt="Sponsor Page Hivos"
                  />
                </a>
              </div>
            </Grid.Column>
            <Grid.Column className="left">
              <div className={`icon-wrapper logo`}>
                <a href="https://www.filmfonds.nl/">
                  <Image
                    className="document-image"
                    src={NLFF}
                    alt="Sponsor Page filmfonds.nl"
                  />
                </a>
              </div>
            </Grid.Column>
            <Grid.Column className="right">
              <div className={`icon-wrapper logo`}>
                <a href="https://www.amsterdamsfondsvoordekunst.nl/">
                  <Image
                    className="document-image"
                    src={AFK}
                    alt="Sponsor Page amsterdamsfondsvoordekunst"
                  />
                </a>
              </div>
            </Grid.Column>
            <Grid.Column className="right">
              <div className={`icon-wrapper logo`}>
                <a href="https://www.fonds21.nl/">
                  <Image
                    className="document-image"
                    src={FONDS21}
                    alt="Sponsor Page fonds21"
                  />
                </a>
              </div>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default PreFooter;
