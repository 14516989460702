/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, Grid, Segment, Image } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { PreFooter } from '../../../../../components';

import instagram from '../../../../../constants/Icons/instagram.svg';
import facebook from '@package/constants/Icons/facebook.svg';
import twitter from '@package/constants/Icons/twitter.svg';
import { useSelector, shallowEqual } from 'react-redux';
/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  const { lang } = useSelector(
    (state) => ({
      lang: state.intl.locale,
    }),
    shallowEqual,
  );
  return (
    <>
      <PreFooter />
      <Segment
        role="contentinfo"
        vertical
        padded
        inverted
        textAlign="center"
        id="footer"
      >
        <Container>
          <Grid stackable columns={4}>
            <div className="footer-link-wrapper">
              <a href={`/${lang}/festival`}>
                <h2>Festival</h2>
              </a>
              <a href={`/${lang}/contact`}>Contact</a>
              <a href={`/${lang}/partners`}>Partners</a>
              <a href={`/${lang}/outreach`}>Outreach</a>
              <a href={`/${lang}/history`}>History</a>
            </div>
            <div className="footer-link-wrapper">
              <a href={`/${lang}/organization`}>
                <h2>Organization</h2>
              </a>
              <a href={`/${lang}/organization/board`}>Board</a>
              <a href={`/${lang}/organization/team`}>Team</a>
              <a href={`/${lang}/organization/financials`}>Financials</a>
              <a href={`/${lang}/organization/jobs`}>Jobs</a>
            </div>
            <div className="footer-link-wrapper">
              <a href={`/${lang}/support`}>
                <h2>Support</h2>
              </a>
              <a href={`/${lang}/donate`}>Hivos & RFD</a>
              <a href={`/${lang}/sponsor`}>Sponsor</a>
              <a href={`/${lang}/volunteer`}>Volunteer</a>
              <a href={`/${lang}/membership`}>Membership</a>
            </div>
            <div className="footer-link-wrapper">
              <a href={`/${lang}/connect`}>
                <h2>Connect</h2>
              </a>
              <a href={`/${lang}/newsletter`}>Newsletter</a>
              <a href={`/${lang}/press`}>Press</a>
              <a href={`/${lang}/privacy`}>Privacy</a>
              <a href={`/${lang}/accessibility`}>Accessibility</a>
            </div>
          </Grid>
        </Container>
        <Container>
          <div className="socialmedia">
            <a href="https://www.instagram.com/rozefilmdagen/">
              <Image src={instagram} alt="instagram" />
            </a>
            <a href="https://www.facebook.com/rozefilmdagen/">
              <Image src={facebook} alt="facebook" />
            </a>
            <a href="https://twitter.com/rozefilmdagen">
              <Image src={twitter} alt="twitter" />
            </a>
          </div>
        </Container>
      </Segment>
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
