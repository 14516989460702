/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

import { Screening, ScreeningListingTemplate } from '@package/components';

export default function applyConfig(config) {
  config.settings = {
    ...config.settings,
    isMultilingual: true,
    supportedLanguages: ['nl', 'en'],
    defaultLanguage: 'nl',
    matomoSiteId: '6',
    matomoUrlBase: 'https://piwik.cleanclothes.org/',
  };

  config.settings.showTags = false;

  config.blocks.blocksConfig.listing.variations.push({
    id: 'screening',
    title: 'Screening',
    template: ScreeningListingTemplate,
  });

  config.views.contentTypesViews['Screening'] = Screening;

  config.blocks.blocksConfig.search.variations[0].isDefault = false;
  config.blocks.blocksConfig.search.variations[2].isDefault = true;
  config.blocks.blocksConfig.search.mostUsed = true;

  return config;
}
